import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SessionFacade } from '@mode/sessions/domain';
import { SessionLoggedOutDialogService } from './session-logged-out-dialog.service';
import { filter, first, map, skipWhile, withLatestFrom } from 'rxjs/operators';
import { FeatureFlag, FeatureFlagsFacade } from '@mode/shared/contract-common';
import { getCurrentUsername } from '@mode/shared/util-js';
import { merge } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private loggedOutSignInFlowEnabled$ = this.featureFlagsFacade.asObservable(FeatureFlag.LoggedOutSignInFlow);

  constructor(
    private sessionFacade: SessionFacade,
    private sessionLoggedOutDialogService: SessionLoggedOutDialogService,
    private featureFlagsFacade: FeatureFlagsFacade,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public watchSession(): void {
    this.sessionFacade.initSessionPolling();
    this.sessionFacade.updateOnTabIsActive();
    merge(this.sessionFacade.sessionIsActive$, this.sessionFacade.localStorageSessionIsActive$)
      .pipe(
        withLatestFrom(this.loggedOutSignInFlowEnabled$),
        skipWhile(([_, flowEnabled]: [boolean, boolean]) => !flowEnabled),
        map(([sessionIsActive, _]: [boolean, boolean]) => sessionIsActive)
      )
      .subscribe((sessionIsActive: boolean) => this.handleSessionActivityChange(sessionIsActive));
  }

  public handleSessionActivityChange(sessionIsActive: boolean): void {
    if (!sessionIsActive) {
      this.openDialog();
    } else {
      if (this.sessionLoggedOutDialogService.dialogIsOpen) {
        this.sessionFacade.tabIsActive$
          .pipe(
            // the next time this tab is active
            filter((isActive: boolean) => isActive),
            // only do this once and unsubscribe
            first()
          )
          .subscribe(() => {
            // reload to get a new CSRF token
            this.document.location.reload();
          });
      } else {
        this.closeDialog();
      }
    }
  }

  public getSignInUrl(): string {
    const username = this.getCurrentUsername();
    const redirectUrl = encodeURIComponent(this.getRedirectUrl());

    return `/signin/options?auth_email=${username}&redirect_to=${redirectUrl}`;
  }

  private openDialog(): void {
    if (!this.sessionLoggedOutDialogService.dialogIsOpen) {
      this.sessionLoggedOutDialogService.open();
    }
  }

  private closeDialog(): void {
    this.sessionLoggedOutDialogService.close();
  }

  private getRedirectUrl(): string {
    return this.document.location.pathname + this.document.location.search;
  }

  private getCurrentUsername(): string {
    return getCurrentUsername(this.document) || '';
  }
}
