import { CommonModule } from '@angular/common';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTableModule } from '@angular/material/legacy-table';
import { MetadataPopoverComponent } from './metadata-popover/metadata-popover.component';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ReportDataTableComponent } from './report-data-table/report-data-table.component';

import { SharedUiModule } from '@mode/shared/ui';
import { SharedUtilDomModule } from '@mode/shared/util-js';
import { TimeAgoPipe } from '@mode/shared/ui';
import { UserAccessAvatarComponent } from './user-access-avatar/user-access-avatar.component';
import { DatasourceAccessLevelComponent } from './metadata-popover/datasource-access-level/datasource-access-level.component';
import { ReportRunBannerComponent } from './report-run-banner/report-run-banner.component';
import { AvatarComponent, IconComponent, TooltipDirective } from '@mode/capra';

@NgModule({
  imports: [
    AvatarComponent,
    CommonModule,
    IconComponent,
    MatLegacyCheckboxModule,
    MatLegacyListModule,
    MatLegacyMenuModule,
    MatLegacyTableModule,
    ReactiveFormsModule,
    SharedUiModule,
    SharedUtilDomModule,
    TimeAgoPipe,
    TooltipDirective,
  ],
  declarations: [
    MetadataPopoverComponent,
    ReportDataTableComponent,
    UserAccessAvatarComponent,
    DatasourceAccessLevelComponent,
    ReportRunBannerComponent,
  ],
  exports: [UserAccessAvatarComponent],
})
export class ReportViewerUiModule {}
