export function bugsnagRouteResolver(url: URL): string {
  if (url.pathname.match(/\/.*\/reports\/.*\/embed/)) {
    return '/:org/reports/:reportToken/embed';
  }

  if (url.pathname.match(/\/home\/.*\/search$/)) {
    return '/home/:org/search';
  }

  if (url.pathname.match(/\/home\/.*\/reports/)) {
    return '/home/:org/reports';
  }

  if (url.pathname.match(/\/home\/.*\/starred/)) {
    return '/home/:org/starred';
  }

  if (url.pathname.match(/\/home\/.*\/discover/)) {
    return '/home/:org/discover';
  }

  if (url.pathname.match(/\/editor\/.*\/datasets\/.*/)) {
    return '/editor/:org/datasets/:datasetToken';
  }

  if (url.pathname.match(/\/editor\/.*\/reports\/new/)) {
    return '/editor/:org/reports/new';
  }

  if (url.pathname.match(/\/editor\/.*\/reports\/.*\/queries\/.*\/new/)) {
    return '/editor/:org/reports/:reportToken/queries/:queryToken/new';
  }

  if (url.pathname.match(/\/editor\/.*\/reports\/.*\/queries\/.*/)) {
    return '/editor/:org/reports/:reportToken/queries/:queryToken';
  }

  if (url.pathname.match(/\/editor\/.*\/reports\/.*\/datasets\/.*/)) {
    return '/editor/:org/reports/:reportToken/datasets/:switchViewToken';
  }

  if (url.pathname.match(/\/editor\/.*\/reports\/.*\/viz\/.*/)) {
    return '/editor/:org/reports/:reportToken/viz/:vizToken';
  }

  if (url.pathname.match(/\/editor\/.*\/reports\/.*/)) {
    return '/editor/:org/reports/:reportToken';
  }

  if (url.pathname.match(/\/.*\/spaces\/.*$/)) {
    return '/:org/spaces/:collectionToken';
  }

  if (url.pathname.match(/\/.*\/reports\/.*$/)) {
    return '/:org/reports/:reportToken';
  }

  return url.pathname;
}
