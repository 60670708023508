import { CapraIconsRegistry } from '@mode/capra';
import { FeatureFlag, FeatureFlagsFacade, FeatureFlagsInitializationService } from '@mode/shared/contract-common';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { InitialAccountsClientService } from '@mode/shared/data-access-webapp';
import { EnvironmentFacade } from '@mode/shared/environment/contract';
import { APP_ICONS_ANALYST_STUDIO, APP_ICONS_MODE, MetaTagsService } from '@mode/shared/util-js';
import { ObservabilityService } from '@mode/shared/util-observability';
import { ThoughtspotIntegrationService } from '@mode/shared/util-thoughtspot-integrated';

export function appInitializer(
  featureFlags: FeatureFlagsInitializationService,
  thoughtspotIntegrationService: ThoughtspotIntegrationService,
  meta: MetaTagsService,
  observabilityService: ObservabilityService,
  iconsRegistry: CapraIconsRegistry,
  environmentFacade: EnvironmentFacade,
  accountClient: InitialAccountsClientService
) {
  environmentFacade.init();

  meta.loadFromWindow(window);

  if (thoughtspotIntegrationService.currOrgIntegrated()) {
    iconsRegistry.registerIcons(APP_ICONS_ANALYST_STUDIO);
  } else {
    iconsRegistry.registerIcons(APP_ICONS_MODE);
  }

  return async () => {
    const accounts = await accountClient.getAccountsNeededForInitialization();
    featureFlags.initFlags(accounts);
    observabilityService.initialize();
  };
}
