// eslint-disable-next-line @nx/enforce-module-boundaries
import { AccountClientService } from '@mode/shared/data-access-webapp';

import { Injectable } from '@angular/core';
import { AllFlags, ErrorReporter, FeatureFlagsFacade, OrgTypes } from '@mode/shared/contract-common';
import { getLaunchDarklyClientId } from '@mode/shared/util-js';
import { ObservabilityEvents, ObservabilityService } from '@mode/shared/util-observability';
import { LDClient } from 'launchdarkly-js-client-sdk';
import {
  AccountsNeededForInitialization,
  FeatureFlagsInitializationService,
} from 'libs/shared/contract-common/src/lib/flags.types';
import { first } from 'rxjs';
import { getFlags } from './feature-flag-helpers';
import { FeatureFlagContext, getAllFlags, init } from './launch-darkly';

async function initFlags(
  launchDarklyFlagsDataService: LaunchDarklyFlagsDataService,
  observabilityService: ObservabilityService,
  { organization, user }: AccountsNeededForInitialization
): Promise<void> {
  const sdkKey = getLaunchDarklyClientId(window.document);

  // Measuring this blocking serial call to api/account#show and LD flag initialization for future optimizations
  observabilityService.mark(ObservabilityEvents.INIT_FLAGS_START);

  if (sdkKey != null) {
    const context: FeatureFlagContext = {};

    if (user != null) {
      if (user.isAnonymous === false) {
        context.email = user.email;
      }
      context.username = user.username;
    }

    if (OrgTypes.isOrg(organization)) {
      context.billing_plan_code = organization.user === true ? 'user' : organization.planCode;
      context.orgName = organization.username;
      context.organization = organization.username;
      context.org_created_at = organization.createdAt;
    }

    await launchDarklyFlagsDataService.init(sdkKey, user?.username, context);
    await attachFlagsToWindow(launchDarklyFlagsDataService);
    observabilityService.recordSpanTiming(ObservabilityEvents.INIT_FLAGS, ObservabilityEvents.INIT_FLAGS_START);
  } else {
    throw new Error('Unable to get LD client key');
  }
}

async function attachFlagsToWindow(featureFlags: LaunchDarklyFlagsDataService) {
  // attach feature flags to window for use during bootstrap (i.e. route configuration)
  const flags = await featureFlags.featureFlagsFacade.allFlags$.pipe(first()).toPromise();
  window['MODE_FLAGS'] = flags;
}

@Injectable({
  providedIn: 'root',
})
export class LaunchDarklyFlagsDataService implements FeatureFlagsInitializationService {
  private client: LDClient | undefined;
  private context: FeatureFlagContext | undefined;

  constructor(
    private window: Window,
    private errorReporter: ErrorReporter,
    public featureFlagsFacade: FeatureFlagsFacade,
    private observabilityService: ObservabilityService
  ) {}

  /** The last context passed into either init or update */
  get currentContext() {
    return this.context;
  }

  /**
   * returns a unique identifier for the user. If anonymous,
   * it will be a UUID, otherwise it should be the username.
   **/
  get userKey(): string | undefined {
    return this.client?.getUser().key;
  }

  /**
   * Initialize the LD client, if not done previously, and retrieve all flags.
   * @param {string} sdkKey the LD client SDK key
   * @param {string} username username of the current user
   * @param {FeatureFlagContext} context
   */
  async init(sdkKey: string, username: string | undefined, context: FeatureFlagContext): Promise<void> {
    if (this.client == null) {
      try {
        const bootstrapConfig = getFlags(this.window.document);
        this.client = init(sdkKey, username, context, bootstrapConfig);

        const flags = await getAllFlags(this.client);
        this.context = context;

        this.featureFlagsFacade.init(flags as AllFlags);
      } catch (err: any) {
        this.errorReporter.notify({ error: err });

        // to prevent blocking the app, make all the flags false
        this.featureFlagsFacade.init({} as AllFlags);
      }
    }
  }

  async initFlags(accounts: AccountsNeededForInitialization) {
    return initFlags(this, this.observabilityService, { ...accounts });
  }
}
