import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionClientService } from '@mode/shared/data-access-webapp';
import { ErrorReporter } from '@mode/shared/contract-common';
import { BrowserService, BrowserStorageKeyName, LocalStorageService } from '@mode/shared/util-js';
import { BehaviorSubject, concat, concatMap, EMPTY, finalize, Observable, of, Subscription } from 'rxjs';
import { catchError, delay, filter, map, mergeMap, skip, skipWhile, withLatestFrom } from 'rxjs/operators';
import { isPresent } from '@mode/shared/util-js';
import { IdleService } from './idle.service';

const POLL_DELAY = 90000; // 90 seconds polling
const IDLE_TIME = 300; // 300 seconds idle

@Injectable({
  providedIn: 'root',
})
export class SessionFacade {
  public sessionIsActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public localStorageSessionIsActive$: Observable<boolean> = this.localStorageService
    .observeItem<boolean>(BrowserStorageKeyName.SessionIsActive)
    .pipe(
      // ignore potential undefined
      filter(isPresent),
      // ensure boolean
      map((isActive) => !!isActive),
      // skip first value
      // because localStorage isn't a reliable source of truth, initially
      skip(1)
    );
  public tabIsActive$ = this.browserService.tabIsActive$;
  private sessionPollSubscription: Subscription | undefined;
  public isIdle = false;
  public pollingWasDeactivated = false;

  constructor(
    private sessionClientService: SessionClientService,
    private errorReporter: ErrorReporter,
    private browserService: BrowserService,
    private idleService: IdleService,
    private localStorageService: LocalStorageService
  ) {
    // Start watching for idle
    this.idleService.startWatching(IDLE_TIME).subscribe((isIdle: boolean) => {
      this.isIdle = isIdle;
    });
  }

  initSessionPolling(): void {
    this.sessionPollSubscription = this.sessionPoll().subscribe((response) => this.updateSessionState(response));
  }

  sessionPoll() {
    const pollController$ = new BehaviorSubject('');
    const pollTask$: Observable<{ active: boolean } | false> = of({}).pipe(
      // get tab state
      withLatestFrom(this.tabIsActive$),
      // pause polling if tab is not active
      skipWhile(([_, tabIsActive]) => tabIsActive === false),
      // pause polling if user is idle
      skipWhile(() => this.isIdle),
      // pause polling if it was deactivated
      skipWhile(() => this.pollingWasDeactivated),
      // request session state
      mergeMap(() => this.getSessionState())
    );
    const pollTimer$ = of('').pipe(
      // wait
      delay(POLL_DELAY),
      // never return a value
      mergeMap(() => EMPTY),
      // restart poll cycle
      finalize(() => pollController$.next(''))
    );
    const pollDriver$ = concat(pollTask$, pollTimer$);

    return pollController$.pipe(concatMap(() => pollDriver$));
  }

  updateOnTabIsActive(): void {
    this.tabIsActive$
      .pipe(
        filter((isActive) => isActive),
        mergeMap(() => this.getSessionState())
      )
      .subscribe((tabisActive: { active: boolean } | false) => {
        this.updateSessionState(tabisActive);
      });
  }

  updateSessionState(response: { active: boolean } | false) {
    if (response) {
      this.sessionIsActive$.next(response.active);
      this.localStorageService.setStringifiedItem(BrowserStorageKeyName.SessionIsActive, response.active);
    }
  }

  getSessionState(): Observable<{ active: boolean } | false> {
    return this.sessionClientService.getSessionState().pipe(
      map((response: null): { active: true } => ({
        active: true,
      })),
      catchError((e: HttpErrorResponse) => {
        if (e.status === 401) {
          return of({ active: false });
        }

        if (e.status === 301) {
          this.pollingWasDeactivated = true;
          return of({ active: true });
        }

        console.error(e);
        this.errorReporter.notify({ error: e });
        return of<false>(false);
      })
    );
  }
}
