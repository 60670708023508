import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpContextToken } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MetaTags, MetaTagsService } from '@mode/shared/util-js';
import { ACCEPT_BASIC_JSON } from '@mode/shared/util-halogen';

const API_URL = /\/api\//i;

/**
 * Adds useful request information to all API calls
 */
@Injectable()
export class CustomHeaderInterceptor implements HttpInterceptor {
  constructor(private meta: MetaTagsService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (API_URL.test(request.url)) {
      const version = this.meta.getTag(MetaTags.WebappRevision);
      const csrfToken = this.meta.getTag(MetaTags.CsrfToken);

      request = request.clone({
        headers: request.headers.set('X-REQUESTED-WITH', 'XMLHttpRequest'),
      });

      if (request.responseType === 'json') {
        if (request.context.get(ACCEPT_BASIC_JSON) === true) {
          request = request.clone({
            headers: request.headers.set('Accept', 'application/json'),
          });
        } else {
          request = request.clone({
            headers: request.headers.set('Accept', 'application/vnd.mode+json'),
          });
        }
      }

      if (version != null) {
        request = request.clone({
          headers: request.headers.set('Webapp-Revision', version),
        });
      }

      if (csrfToken != null) {
        request = request.clone({
          headers: request.headers.set('X-CSRF-Token', csrfToken),
        });
      }
    }

    return next.handle(request);
  }
}
