import { tap, timeout, retry, filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpEventType,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, timer, throwError, TimeoutError } from 'rxjs';

import { FlamingoResponse } from '@mode/shared/contract-common';
import { MetaTagsService } from '@mode/shared/util-js';
import { requestWithMetaHeaders } from '../flamingo-headers';

const READY_URL = /\/datasets\/[a-z0-9]+$/;
const HELIX_EXECUTING_URL = /\/helix\/switch_plan_executions\/v1\/.+$/;

export function calculateDelay(attemptNum: number, delay = 50, factor = 2, maxDelay = 10000) {
  if (factor) {
    delay *= Math.pow(factor, attemptNum - 1);
    if (maxDelay !== 0) {
      delay = Math.min(delay, maxDelay);
    }
  }

  return Math.round(delay);
}

@Injectable()
export class FlamingoRetryInterceptor implements HttpInterceptor {
  maxDelayMs = 600000;

  constructor(private metaTagsService: MetaTagsService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (HELIX_EXECUTING_URL.test(request.url)) {
      const reqWithHeaders = requestWithMetaHeaders(this.metaTagsService, request);
      return next.handle(reqWithHeaders).pipe(
        filter((event) => event.type !== 0), // Don't care about "sent" type events. It also messes with the timeout operator in RxJS 7
        timeout(3000), // If the ready call takes longer than 3 seconds, assume it's basically allocated
        tap((response) => {
          if (response.type === HttpEventType.Response) {
            if ('status' in response.body) {
              const result = response.body as FlamingoResponse.HelixSelectResponse;
              if (result.status === FlamingoResponse.HelixResponseStatus.Executing) {
                throw new Error('Retry'); // TODO: Make a custom error
              }
            }
          }
        }),
        retry({
          delay: (error: HttpErrorResponse, retryCount) => {
            if (error.message == 'Retry' || error instanceof TimeoutError) {
              const delay = calculateDelay(retryCount);
              return timer(delay);
            }

            return throwError(() => error);
          },
        }),
        timeout({ first: this.maxDelayMs }) // This will throw an error with name = 'TimeoutError'
      );
    }

    if (READY_URL.test(request.url) && !request.url.includes('/api/')) {
      const reqWithHeaders = requestWithMetaHeaders(this.metaTagsService, request);
      return next.handle(reqWithHeaders).pipe(
        filter((event) => event.type !== 0), // Don't care about "sent" type events. It also messes with the timeout operator in RxJS 7
        timeout(3000), // If the ready call takes longer than 3 seconds, assume it's basically allocated
        tap((response) => {
          if (response.type === HttpEventType.Response) {
            if ('state' in response.body) {
              const result = response.body as FlamingoResponse.DatasetResult;
              if (
                result.state == FlamingoResponse.DatasetState.Allocated ||
                result.state === FlamingoResponse.DatasetState.Created
              ) {
                throw new Error('Retry'); // TODO: Make a custom error
              }
            }
          }
        }),
        retry({
          delay: (error: HttpErrorResponse, retryCount) => {
            if (error.message == 'Retry' || error instanceof TimeoutError) {
              const delay = calculateDelay(retryCount);
              return timer(delay);
            }

            return throwError(() => error);
          },
        }),
        timeout({ first: this.maxDelayMs }) // This will throw an error with name = 'TimeoutError'
      );
    } else if (request.url.endsWith('/selects') && request.method === 'POST') {
      return next.handle(request).pipe(
        filter((event) => event.type !== 0), // Don't care about "sent" type events. It also messes with the timeout operator in RxJS 7
        retry({
          delay: (error: HttpErrorResponse, retryCount) => {
            if (error.status === 429 || error.status === 503) {
              const retryAfterHeader = error.headers.get('Retry-After');
              const delay = retryAfterHeader ? parseInt(retryAfterHeader) : calculateDelay(retryCount);
              return timer(delay);
            }

            return throwError(() => error);
          },
        }),
        timeout({ first: this.maxDelayMs })
      );
    }

    return next.handle(request);
  }
}
