import { getDebugJS } from '@mode/shared/util-js';
import { ConsoleLogger } from './console-logger.service';
import { NullLogger } from './null-logger.service';

export function createLoggerService(window: Window) {
  const metaDebug = getDebugJS(window.document) === 'true';
  const urlDebug = new URLSearchParams(window.location.search).get('debugjs') === 'true';
  if (metaDebug || urlDebug) {
    return new ConsoleLogger();
  } else {
    return new NullLogger();
  }
}
