import { HttpRequest } from '@angular/common/http';
import { MetaTags, MetaTagsService } from '@mode/shared/util-js';

export const XModeMeta = `X-Mode-Meta`;

export function requestWithMetaHeaders(meta: MetaTagsService, request: HttpRequest<unknown>) {
  const organization = meta.getTag(MetaTags.ReportOwnerUsername);
  const user = meta.getTag(MetaTags.CurrentUsername);
  const dataset = request.headers.get(`X-Dataset-Name`);

  /**
   * Attempt to parse the report token from a run url
   */
  let report: string | undefined;
  {
    const runPath = meta.getTag(MetaTags.ReportRunEntryPath) ?? '';
    const match = String(runPath).match(/\/reports\/([a-fA-F0-9]{12,})\/?/) ?? [];
    if (typeof match[1] === 'string') {
      report = match[1];
    }
  }

  return request.clone({
    headers: request.headers
      .append(XModeMeta, `Organization=${organization ?? ''}`)
      .append(XModeMeta, `User=${user ?? ''}`)
      .append(XModeMeta, `Report=${report ?? ''}`)
      .append(XModeMeta, `Dataset=${dataset ?? ''}`),
  });
}
