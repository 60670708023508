import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SecretKeyInterceptor } from './interceptors/secret-key.interceptor';
import { SharedUtilDomModule } from '@mode/shared/util-js';
import { CustomHeaderInterceptor } from './interceptors/custom-header.interceptor';
import { LegacyDatasetInterceptor } from './interceptors/legacy-dataset.interceptor';
import { PreloadInterceptor } from './interceptors/preload.interceptor';
import { TemplateParser, TEMPLATE_PARSER, UriTemplateService } from '@mode/shared/util-halogen';

@NgModule({
  imports: [CommonModule, SharedUtilDomModule],
  providers: [
    { provide: TEMPLATE_PARSER, useClass: UriTemplateService },
    { provide: HTTP_INTERCEPTORS, useClass: CustomHeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SecretKeyInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LegacyDatasetInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: PreloadInterceptor, multi: true },
  ],
})
export class SharedDataAccessWebappModule {}
